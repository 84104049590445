const firebaseConfig = {
  apiKey: "AIzaSyDwPCJOGErzuYr7BjbDBzywDfr1zrkomkk",
  authDomain: "code-4-change-spring-2023.firebaseapp.com",
  projectId: "code-4-change-spring-2023",
  storageBucket: "code-4-change-spring-2023.appspot.com",
  messagingSenderId: "1026958175902",
  appId: "1:1026958175902:web:bb6488eebde41eb5b7558f",
};

export default firebaseConfig;
